import ActionTypes from '../actiontypes/company'
import { COMPANY_ROLE } from '../components/company/constants'
import { ROLE_KEYS } from '../components/maklerpremium/epd/AddAgreementPage/AddAgreementFormSteps/Step1/helpers'

/** @function
 * @name getCompany
 * @description An action method to load a single company by id
 * @return {Object}
 */
export const getCompany = company => ({
  type: ActionTypes.GET_COMPANY_REQUEST,
  payload: company,
})

/**
 * @function
 * @name resetCompany
 * @description An action method to reset the item state of the current loaded company
 * @return {Object}
 */
export const resetCompany = () => ({
  type: ActionTypes.RESET_COMPANY,
})

/** @function
 * @name getActiveCompanies
 * @description An action method to get a list of active companies
 * @return {Object}
 */
export const getActiveCompanies = filters => ({
  type: ActionTypes.GET_ACTIVE_COMPANIES_REQUEST,
  payload: filters,
})

/**
 * @function
 * @name resetActiveCompanies
 * @description An action method to reset the list of all active companies
 * @return {Object}
 */
export const resetActiveCompanies = () => ({
  type: ActionTypes.RESET_ACTIVE_COMPANIES,
})

/** @function
 * @name getActiveCompaniesForFilter
 * @description An action method to get a list of active companies
 * @return {Object}
 */
export const getActiveCompaniesForFilter = filters => ({
  type: ActionTypes.GET_ACTIVE_COMPANIES_FOR_FILTER_REQUEST,
  payload: filters,
})

/** @function
 * @name getAllCompaniesForFilter
 * @description An action method to get a list of all companies
 * @return {Object}
 */
export const getAllCompaniesForFilter = filters => ({
  type: ActionTypes.GET_ALL_COMPANIES_FOR_FILTER_REQUEST,
  payload: filters,
})

/** @function
 * @name getActiveCustomer
 * @description An action method to get a list of active companies
 * @return {Object}
 */
export const getActiveCustomerForFilter = filters => ({
  type: ActionTypes.GET_ACTIVE_CUSTOMER_FOR_FILTER_REQUEST,
  payload: {
    ...filters,
    role: COMPANY_ROLE.WASTE_PRODUCER,
  },
})

/** @function
 * @name getActiveCompanies
 * @description An action method to get a list of active companies
 * @return {Object}
 */
export const getActivePartnerForFilter = filters => ({
  type: ActionTypes.GET_ACTIVE_PARTNER_FOR_FILTER_REQUEST,
  payload: {
    ...filters,
    role: COMPANY_ROLE.WASTE_COMPANY,
  },
})

/** @function
 * @name getCompanies
 * @description An action method to get a list of all companies (activated and not)
 * @return {Object}
 */
export const getCompanies = filters => ({
  type: ActionTypes.GET_COMPANIES_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name getMaklerPremiumCompanies
 * @description An action method to get a list of all customer companies
 * @return {Object}
 */
export const getMaklerPremiumCompanies = (page, filters) => ({
  type: ActionTypes.GET_MAKLER_PREMIUM_COMPANIES_REQUEST,
  payload: {
    ...filters,
    page,
  },
})

/**
 * @function
 * @name getCollectiveInvoiceCompanies
 * @description An action method to get a list of all collective invoice companies
 * @return {Object}
 */
export const getCollectiveInvoiceCompanies = (page, filters) => ({
  type: ActionTypes.GET_COLLECTIVE_INVOICE_COMPANIES_REQUEST,
  payload: {
    ...filters,
    page,
  },
})

/**
 * @function
 * @name createCollectiveInvoice
 * @description An action method to trigger collective invoices for company
 * @return {Object}
 */
export const createCollectiveInvoice = companyId => ({
  type: ActionTypes.CREATE_COLLECTIVE_INVOICE_REQUEST,
  payload: {
    companyId,
  },
})

/**
 * @function
 * @name createTotalCollectiveInvoice
 * @description An action method to trigger collective invoices for all companies
 * @return {Object}
 */
export const createTotalCollectiveInvoice = () => ({
  type: ActionTypes.CREATE_TOTAL_COLLECTIVE_INVOICE_REQUEST,
  payload: {},
})

/**
 * @function
 * @name getCompaniesForFilter
 * @description An action method to get a list of all companies serialized for filter usage
 * @return {Object}
 */
export const getCompaniesForFilter = filters => ({
  type: ActionTypes.GET_COMPANIES_FOR_FILTER_REQUEST,
  payload: {
    ...filters,
  },
})

/**
 * @function
 * @name resetMaklerPremiumCompanies
 * @description An action method to get a list of all companies (activated and not)
 * @return {Object}
 */
export const resetMaklerPremiumCompanies = () => ({
  type: ActionTypes.RESET_MAKLER_PREMIUM_COMPANIES,
})

/** @function
 * @name updateCompany
 * @description Updates an existing company. It uses the ID from the object to generate the route.
 * @param company CompanyScheme the object including its ID.
 * @return {Object}
 */
export const updateCompany = company => ({
  type: ActionTypes.UPDATE_COMPANY_REQUEST,
  payload: company,
})

/** @function
 * @name getMyOffersInquiredCompanies
 * @description Get all the companies from the list of my offers
 * @return {Object}
 */
export const getMyOffersInquiredCompanies = () => ({
  type: ActionTypes.GET_MY_OFFERS_INQUIRED_COMPANIES_REQUEST,
})

/**
 * @function
 * @name exportMaklerPremiumCompanies
 * @description Export companies of either role WASTE_DISPOSER or WASTE_PRODUCER belonging to the logged in Makler
 * Premium
 * @return {Object}
 */
export const exportMaklerPremiumCompanies = filters => ({
  type: ActionTypes.EXPORT_MAKLER_PREMIUM_COMPANIES_REQUEST,
  payload: {
    filters,
  },
})

export const pauseUnpauseCompany = (
  companyId,
  start_date,
  end_date,
  reason,
) => ({
  type: ActionTypes.PAUSE_COMPANY_REQUEST,
  payload: {
    id: companyId,
    block_auto_offers_start_date: start_date,
    block_auto_offers_end_date: end_date,
    block_auto_offers_reason: reason,
  },
})

/**
 * @function
 * @name getCustomInvoiceCompanies
 * @description An action method to get a list of companies for creating a custom invoice
 * @return {Object}
 */
export const getCustomInvoiceCompanies = filters => ({
  type: ActionTypes.GET_CUSTOM_INVOICE_COMPANIES_REQUEST,
  payload: {
    ...filters,
  },
})

/**
 * @function
 * @name resetMaklerPremiumCompanies
 * @description An action method to reset the items state of companies
 * @return {Object}
 */
export const resetCustomInvoiceCompanies = () => ({
  type: ActionTypes.RESET_CUSTOM_INVOICE_COMPANIES,
})

/**
 * @function
 * @name addCustomerCompanyToState
 * @description Adds a customer company to redux state
 * @param company Company
 * @return {Object}
 */
export const addCustomerCompanyToState = company => ({
  type: ActionTypes.ADD_CUSTOMER_COMPANY,
  company: company,
})

/**
 * @function
 * @name addCustomerCompanyToState
 * @description Adds a partner company to redux state
 * @param company Company
 * @return {Object}
 */
export const addPartnerCompanyToState = company => ({
  type: ActionTypes.ADD_PARTNER_COMPANY,
  company: company,
})

/**
 * @function
 * @name resetAgreementCompany
 * @description Resets either the customer or the partner company in redux state
 * @param role A company's role
 * @return {Object}
 */
export const resetAgreementCompany = role => {
  switch (role) {
    case ROLE_KEYS.CUSTOMER:
      return {
        type: ActionTypes.RESET_CUSTOMER_COMPANY,
      }
    case ROLE_KEYS.PARTNER:
      return {
        type: ActionTypes.RESET_PARTNER_COMPANY,
      }
    default:
      return { type: undefined }
  }
}

/**
 * @function
 * @name getCompanyNotes
 * @description Gets a company's notes
 * @param companyId Company ID
 * @param page_size page Size
 * @param page
 * @return {Object}
 */
export const getCompanyNotes = ({ companyId, page_size = 3, page = 1 }) => ({
  type: ActionTypes.GET_COMPANY_NOTES_REQUEST,
  payload: { company: companyId, page_size, page },
})

/**
 * @function
 * @name deleteCompanyNote
 * @description Deletes a company Note
 * @param note number
 * @return {Object}
 */
export const deleteCompanyNote = note => ({
  type: ActionTypes.DELETE_COMPANY_NOTE_REQUEST,
  payload: note,
})

/**
 * @function
 * @name addCompanyNote
 * @description Adda a new note to a Company
 * @param company number
 * @param note_content string
 * @param resetField
 * @return {Object}
 */
export const addCompanyNote = (company, note_content, resetField) => ({
  type: ActionTypes.ADD_COMPANY_NOTE_REQUEST,
  payload: { company, content: note_content, resetField },
})

/**
 * @function
 * @name resetCompanyNotes
 * @description Resets the company's notes
 * @return {Object}
 */
export const resetCompanyNotes = () => ({
  type: ActionTypes.RESET_COMPANY_NOTES,
})

/**
 * @function
 * @name getComCenterContacts
 * @description Gets a company's notes
 * @param company Company ID
 * @param page_size page Size
 * @param page
 * @return {Object}
 */
export const getComCenterContacts = (company, page = 1, page_size = 10) => ({
  type: ActionTypes.GET_COM_CENTER_CONTACTS_REQUEST,
  payload: { company, page, page_size },
})

/**
 * @function
 * @name addComCenterContact
 * @description Add a contact in the communication center
 * @return {Object}
 */
export const addComCenterContact = comCenterContactData => ({
  type: ActionTypes.ADD_COM_CENTER_CONTACT_REQUEST,
  payload: comCenterContactData,
})

/**
 * @function
 * @name updateComCenterContact
 * @description Update an existing company communication contact
 * @param comCenterContact
 */
export const updateComCenterContact = comCenterContact => ({
  type: ActionTypes.UPDATE_COM_CENTER_CONTACT_REQUEST,
  payload: comCenterContact,
})

/**
 * @function
 * @name deleteComCenterContact
 * @description delete a contact in the communication center
 * @param comCenterContactId number
 * @return {Object}
 */
export const deleteComCenterContact = comCenterContactId => ({
  type: ActionTypes.DELETE_COM_CENTER_CONTACT_REQUEST,
  payload: comCenterContactId,
})

/**
 * @function
 * @name getComCenterLog
 * @description get log for communication center
 * @param comCenterContactId number
 * @return {Object}
 */
export const getComCenterLog = comCenterContactId => ({
  type: ActionTypes.GET_COM_CENTER_LOG_REQUEST,
  payload: comCenterContactId,
})

export const getTaskLogs = (page, filters) => {
  return {
    type: ActionTypes.GET_TASK_LOGS_REQUEST,
    payload: {
      ...filters,
      page,
    },
  }
}

export const getTaskLogMessages = (
  page = 1,
  page_size = 10,
  filters,
  task_log_id,
) => {
  let data = {
    ...filters,
    page,
    page_size,
  }

  if (task_log_id) data = { ...data, task_log_id }

  return {
    type: ActionTypes.GET_TASK_LOG_MESSAGES_REQUEST,
    payload: data,
  }
}

/**
 * @function
 * @name getCompanyHistory
 * @description Gets the company history
 * @param id Company
 * @param page_size page Size
 * @param page
 * @return {Object}
 */
export const getCompanyHistory = (id, page = 1, page_size = 10) => ({
  type: ActionTypes.GET_COMPANY_HISTORY_REQUEST,
  payload: { id, page, page_size },
})

export const getFrameworkContractForCompany = companyId => ({
  type: ActionTypes.GET_FRAMEWORK_CONTRACT_FOR_COMPANY_REQUEST,
  payload: { company_id: companyId },
})
